<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Product</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="productRequest.status"
                        label="Active"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="productRequest.needsApproval"
                        label="Needs Approval"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="productRequest.stylable"
                        label="Stylable"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="productRequest.colorSelection"
                        label="Color Selection"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    v-if="userLoggedGetters.profile.systemAdmin == 1"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="productRequest.onlyAdminEditPrice"
                        label="Only admins can change the price"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    v-if="userLoggedGetters.profile.systemAdmin == 1"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="productRequest.customOrder"
                        label="Custom Order"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="productRequest.priceDefinedByAdmin"
                        label="Price must be specified by admin"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="productRequest.showInContract"
                        label="Show product in contract"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Category</label>
                    <v-combobox
                        v-model="productRequest.productCategorySelected"
                        :items="listProductCategory"
                        :rules=[validations.required]
                        item-text="description"
                        item-value="id"
                        @change="productRequest.productSubCategorySelected = null; getListSubCategory()"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Sub Category</label>
                    <v-combobox
                        v-model="productRequest.productSubCategorySelected"
                        :items="listProductSubCategory"
                        item-text="description"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    :lg="showPrice == false ? 12 : 6"
                    :md="showPrice == false ? 12 : 6"
                    sm="12"
                >
                    <label>Name</label>
                    <v-text-field
                        v-model="productRequest.description" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    v-if="showPrice"
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Price</label>
                    <v-text-field
                        v-model="productRequest.price" 
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Description</label>
                    <v-textarea
                        v-model="productRequest.details"
                        single-line
                        rows="4"
                        row-height="30"
                        outlined
                    >
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Unit</label>
                    <v-combobox
                        v-model="productRequest.unitySelected"
                        :items="listUnity"
                        item-text="symbolDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                >
                    <label>Order</label>
                    <v-text-field
                        v-model="productRequest.order"
                        v-mask="'####'" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                >
                    <label>Warranty</label>
                    <v-text-field
                        v-model="productRequest.warrantyDays"
                        v-mask="'####'" 
                        suffix="days"
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Select the types of projects that this product should appear in the scope of work'" />
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <label>Type of Work</label>
                    <v-combobox
                        v-model="productRequest.typeWorkSelected"
                        :items="listTypeWork" 
                        :rules=[validations.required]
                        item-text="kindWorkDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <div class="adjustmentInput">
                        <v-switch 
                            v-model="productRequest.projectPool"
                            label="Pool ?"
                            :value=1
                            color="var(--color__main)"
                        >
                        </v-switch>
                        <v-switch
                            v-if="productRequest.projectPool == 1"
                            v-model="productRequest.mandatoryPool"
                            label="Mandatory"
                            :value=1
                            color="var(--color__main)"
                        >
                        </v-switch>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <div class="adjustmentInput">
                        <v-switch 
                            v-model="productRequest.projectBBQ"
                            label="BBQ ?"
                            :value=1
                            color="var(--color__main)"
                        >
                        </v-switch>
                        <v-switch
                            v-if="productRequest.projectBBQ == 1"
                            v-model="productRequest.mandatoryBBQ"
                            label="Mandatory"
                            :value=1
                            color="var(--color__main)"
                        >
                        </v-switch>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <div class="adjustmentInput">
                        <v-switch 
                            v-model="productRequest.projectPergola"
                            label="Pergola ?"
                            :value=1
                            color="var(--color__main)"
                        >
                        </v-switch>
                        <v-switch
                            v-if="productRequest.projectPergola == 1"
                            v-model="productRequest.mandatoryPergola"
                            label="Mandatory"
                            :value=1
                            color="var(--color__main)"
                        >
                        </v-switch>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Automation'" />
                </v-col>
            </v-row>

            <v-row>
                <v-col
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-switch 
                        v-model="productRequest.automatable"
                        label="Automatable"
                        :value=1
                        color="var(--color__main)"
                        @change="checkAutomation()"
                    >
                    </v-switch>
                </v-col>
                <v-col
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-switch 
                        v-if="productRequest.automatable == 1"
                        v-model="productRequest.onlyOneChannelConsumed"
                        label="Only one channel is consumed"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col
                    v-if="productRequest.automatable == 0 || productRequest.automatable == null"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-switch 
                        v-model="productRequest.automationCenter"
                        label="Number of auxiliaries"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                    <label v-if="productRequest.automationCenter == 1">Channel Qty</label>
                    <v-text-field
                        v-if="productRequest.automationCenter == 1"
                        v-model="productRequest.channelQty"
                        v-mask="'###'"
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Color Selection'" />
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Select all categories of color selection to link in work order</label>
                    <v-combobox
                        v-model="productRequest.categoryColorSelectionSelected"
                        :items="listCategoryColorSelection"
                        item-text="categoryColorSelectionDescription"
                        item-value="id"
                        chips
                        multiple
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>

            
            <v-row>
                <v-col
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="productRequest.showChipInCard"
                        label="Show Icon on Card"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                    <label v-if="productRequest.showChipInCard == 1">Icon chip to show in card</label>
                    <v-text-field
                        v-if="productRequest.showChipInCard == 1"
                        v-model="productRequest.iconWithoutPrefix" 
                        single-line
                        :prefix="prefix"
                        @keyup="setMdiIcon()"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>

                <v-col 
                    style="display: flex; align-content: center; align-items: center; margin-top: 60px;"
                    cols="1"
                    lg="1"
                    md="1"
                    sm="1"
                >
                    <v-icon 
                        v-if="productRequest.showChipInCard == 1"
                        large
                    >
                        {{productRequest.iconChip}}
                    </v-icon>
                    <v-tooltip 
                        v-if="productRequest.showChipInCard == 1"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <a 
                                v-if="productRequest.showChipInCard == 1"
                                href="https://materialdesignicons.com/"
                                target="_blank"
                            >
                                <v-chip
                                    class="ma-2"
                                    label
                                    link
                                    outlined
                                    style="border: none; font-size: 30px !important;"
                                    v-on="on"
                                >
                                    ...
                                </v-chip>
                            </a>
                        </template>
                        <span>To Go Site - Material Designer</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="productRequest.highlight"
                        label="Highlight on Card?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                    <label v-if="productRequest.highlight == 1">Highlight Description</label>
                    <v-text-field
                        v-if="productRequest.highlight == 1"
                        v-model="productRequest.highlightDescription"
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                >
                    <canvas id="canvas" hidden></canvas>
                    <label>Photos of Product</label>
                    <v-file-input
                        v-model="productFilesPhotos" 
                        accept="image/*"
                        small-chips
                        multiple
                        outlined
                        placeholder="Click here to add"
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon=""
                        @change="resizePreviewImage(productFilesPhotos, productPhotos)"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    v-for="(itemPhoto, index) in productPhotos.filter(pht => pht.deleted == false)"
                    :key="index"
                    cols="12"
                    lg="1"
                    md="4"
                    sm="12"
                >
                    <v-card max-width="200">
                        <v-img
                            :src="itemPhoto.src"
                            :lazy-src="itemPhoto.src"
                            aspect-ratio="1"
                            max-height="200"
                            max-width="200"
                        >
                            <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                            </template>
                        </v-img>
                        <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                        <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(itemPhoto)">mdi mdi-delete</v-icon>
                    </v-card>
                </v-col>
            </v-row>


            <v-row>
                <v-col
                    cols="12"
                >
                    <canvas id="canvas" hidden></canvas>
                    <label>Other Documents</label>
                    <v-file-input
                        v-model="productFilesDocs" 
                        accept=".pdf,image/*"
                        small-chips
                        multiple
                        outlined
                        placeholder="Click here to add"
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon=""
                        @change="resizePreviewImage(productFilesDocs, productDocs)"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    v-for="(itemDoc, index) in productDocs.filter(doc => doc.deleted == false)"
                    :key="index"
                    cols="12"
                    lg="1"
                    md="4"
                    sm="12"
                >
                    <v-card
                        v-if="itemDoc.type.toString().toLowerCase().includes('pdf')"
                        max-width="200"
                        class="justify-center"
                        @click="downloadFile(itemDoc.id, itemDoc.fileName)"
                    >
                        <v-card-text
                            style="height: 129px; text-align: center;"
                        >
                            <v-icon x-large>mdi mdi-file-pdf-box</v-icon>
                            <br />
                            PDF File
                        </v-card-text>
                        <span class="imageFileName">{{ itemDoc.fileName }}</span>
                        <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(itemDoc)">mdi mdi-delete</v-icon>
                    </v-card>

                    <v-card 
                        v-else
                        max-width="200"
                    >
                        <v-img
                            :src="itemDoc.src"
                            :lazy-src="itemDoc.src"
                            aspect-ratio="1"
                            max-height="200"
                            max-width="200"
                        >
                            <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                            </template>
                        </v-img>
                        <span class="imageFileName">{{ itemDoc.fileName }}</span>
                        <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(itemDoc)">mdi mdi-delete</v-icon>
                    </v-card>
                </v-col>
            </v-row>

            <ActionButtons
                :request="productRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />

            <ActionDialog 
                v-on:methodConfirmToCall="dialog.methodConfirm"
                :showDialog.sync="dialog.show"
                :headerTitle="dialog.headerText"
                :bodyText="dialog.bodyText"
                :params="dialog.params"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { formaterDecimalBRServer } from '@/utilities/Utils';
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT 
    } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            ActionDialog,
            ContentHeader
        },

        data: () => ({

            validForm: true,

            prefix: 'mdi mdi-',

            productFilesPhotos: [],
            productPhotos: [],

            productFilesDocs: [],
            productDocs: [],

            itemPhotoDelete: {},

            productRequest: {
                id: 0,
                status: 1,
                needsApproval: 1,
                stylable: 1,
                colorSelection: 0,
                idProductCategory: 0,
                idProductSubCategory: 0,
                description: "",
                details: "",
                price: "$ 0,00",
                unitySelected: null,
                order: 1,
                warrantyDays: 1,
                showChipInCard: 0,
                iconChip: "",
                iconWithoutPrefix: "",
                automatable: 0,
                automationCenter: 0,
                channelQty: 0,
                onlyOneChannelConsumed: 0,
                highlight: 0,
                highlightDescription: '',
                projectPool: 0,
                mandatoryPool: 1,
                projectBBQ: 0,
                mandatoryBBQ: 1,
                projectPergola: 0,
                mandatoryPergola: 1,
                onlyAdminEditPrice: 0,
                customOrder: 0,
                priceDefinedByAdmin: 0,
                categoryColorSelectionSelected: null,
                showInContract: 0
            },

            moneyFormat: {
                prefix: '$ ',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },

            listProductCategory: [],
            listProductSubCategory: [],
            listUnity: [],
            listTypeWork: [],
            listCategoryColorSelection: [],

            loading: false,
            
            validations: {
                required: required,
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            showPrice() {
                return this.productRequest.onlyAdminEditPrice == 0 || this.userLoggedGetters.profile.systemAdmin == 1
            }
        },

        methods: {

            checkAutomation() {
                this.productRequest.automationCenter = 0;
                this.productRequest.channelQty = 0;
            },

            setMdiIcon() {
                this.productRequest.iconChip = this.prefix + this.productRequest.iconWithoutPrefix;
            },

            async confirmPhotoDelete(itemPhotoToDelete) {

                this.itemPhotoToDelete = itemPhotoToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this file [${itemPhotoToDelete.fileName}], confirm your decision?`,
                    methodConfirm: this.deletePhoto,
                    params: 0
                };
            },

            async deletePhoto() {

                this.itemPhotoToDelete.deleted = true;
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("productModule/GetById", this.id);

                    if (response.success) {
                        this.productRequest = response.result;
                        this.productRequest.price = this.productRequest.priceFormatted;
                        this.productRequest.iconWithoutPrefix = this.productRequest.iconChip.replace("mdi mdi-", "");

                        this.getListSubCategory();
                                                
                        this.productFilesPhotos = [];
                        this.productPhotos = [];
                                                
                        this.productFilesDocs = [];
                        this.productDocs = [];

                        let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PRODUCT.value, idKey: this.id });
                        let listPhotos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PRODUCT.value);  
                        listPhotos.forEach(itemPhoto => {

                            this.productPhotos.push({
                                id: itemPhoto.id,
                                file: new File([], itemPhoto.fileName),
                                type: itemPhoto.fileType,
                                fileName: itemPhoto.fileNameShort,
                                deleted: false,
                                src: `data:image/jpg;base64,${itemPhoto.file}`
                            })

                        })

                        let listDocs = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PRODUCT_DOCS.value);  
                        listDocs.forEach(itemPhoto => {

                            this.productDocs.push({
                                id: itemPhoto.id,
                                file: new File([], itemPhoto.fileName),
                                type: itemPhoto.fileType,
                                fileName: itemPhoto.fileNameShort,
                                deleted: false,
                                src: `data:image/jpg;base64,${itemPhoto.file}`
                            })
                        })
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/product/productList" });
            },

            async getLists() {
                
                this.listProductCategory = await this.$store.dispatch("productCategoryModule/List");
                this.listUnity = await this.$store.dispatch("unityModule/List");

                const listTypeWork = await this.$store.dispatch("serviceModule/ListTypeOfWorks");
                this.listTypeWork = listTypeWork.filter(tow => tow.id >= 1);

                this.listCategoryColorSelection = await this.$store.dispatch("serviceModule/ListCategoryOfColorSelection");
            },

            async getListSubCategory() {

                let idProductCategory = 0;
                
                if (this.productRequest.productCategorySelected != null && this.productRequest.productCategorySelected != undefined) {
                    idProductCategory = this.productRequest.productCategorySelected.id;
                }

                this.listProductSubCategory = await this.$store.dispatch("productSubCategoryModule/ListByCategory", idProductCategory);
            },

            async uploadFiles(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.PRODUCT.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async savePhotos() {

                let allResult = []

                this.productPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadFiles(itemPhoto.id, this.productRequest.id, itemPhoto.file, TYPE_DOCUMENT.PRODUCT.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PRODUCT.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `Error to upload photos: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async saveDocs() {

                let allResult = []

                this.productDocs.forEach(async itemPhoto => {

                    let result = await this.uploadFiles(itemPhoto.id, this.productRequest.id, itemPhoto.file, TYPE_DOCUMENT.PRODUCT_DOCS.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PRODUCT_DOCS.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `Error to upload docs: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.productRequest.id = this.id;
                    
                    if (this.productRequest.status === null) {
                        this.productRequest.status = 0;
                    }
                    
                    if (this.productRequest.needsApproval === null) {
                        this.productRequest.needsApproval = 0;
                    }
                    
                    if (this.productRequest.mandatoryPool === null) {
                        this.productRequest.mandatoryPool = 0;
                    }
                    
                    if (this.productRequest.mandatoryBBQ === null) {
                        this.productRequest.mandatoryBBQ = 0;
                    }
                    
                    if (this.productRequest.mandatoryPergola === null) {
                        this.productRequest.mandatoryPergola = 0;
                    }
                    
                    if (this.productRequest.stylable === null) {
                        this.productRequest.stylable = 0;
                    }
                    
                    if (this.productRequest.colorSelection === null) {
                        this.productRequest.colorSelection = 0;
                    }
                    
                    if (this.productRequest.onlyAdminEditPrice === null) {
                        this.productRequest.onlyAdminEditPrice = 0;
                    }
                    
                    if (this.productRequest.customOrder === null) {
                        this.productRequest.customOrder = 0;
                    }
                    
                    if (this.productRequest.priceDefinedByAdmin === null) {
                        this.productRequest.priceDefinedByAdmin = 0;
                    }
                    
                    if (this.productRequest.showInContract === null) {
                        this.productRequest.showInContract = 0;
                    }
                    
                    if (this.productRequest.showChipInCard === null) {
                        this.productRequest.showChipInCard = 0;
                    }

                    if (this.productRequest.showChipInCard == 0) {
                        this.productRequest.iconChip = "";
                    }

                    this.productRequest.idProductCategory = this.productRequest.productCategorySelected.id;
                    this.productRequest.idProductSubCategory = 0;

                    if (this.productRequest.productSubCategorySelected != null && this.productRequest.productSubCategorySelected != undefined) {
                        this.productRequest.idProductSubCategory = this.productRequest.productSubCategorySelected.id;
                    }

                    if (this.productRequest.automatable === null) {
                        this.productRequest.automatable = 0;
                        this.productRequest.onlyOneChannelConsumed = 0;
                    }

                    if (this.productRequest.automationCenter === null) {
                        this.productRequest.automationCenter = 0;
                        this.productRequest.channelQty = 0;
                    }

                    if (this.productRequest.onlyOneChannelConsumed === null) {
                        this.productRequest.onlyOneChannelConsumed = 0;
                    }

                    if (this.productRequest.highlight === null) {
                        this.productRequest.highlight = 0;
                        this.productRequest.highlightDescription = '';
                    }

                    if (this.productRequest.projectPool === null) {
                        this.productRequest.projectPool = 0;
                    }

                    if (this.productRequest.projectBBQ === null) {
                        this.productRequest.projectBBQ = 0;
                    }

                    if (this.productRequest.projectPergola === null) {
                        this.productRequest.projectPergola = 0;
                    }

                    if (this.productRequest.typeWorkSelected != null && this.productRequest.typeWorkSelected != undefined) {
                        this.productRequest.idKindWork = this.productRequest.typeWorkSelected.id;
                    }

                    this.productRequest.price = formaterDecimalBRServer(this.productRequest.price); 

                    if (this.productRequest.unitySelected != null && this.productRequest.unitySelected != undefined) {
                        this.productRequest.idUnity = this.productRequest.unitySelected.id;
                    }

                    if (this.productRequest.categoryColorSelectionSelected != null && this.productRequest.categoryColorSelectionSelected != undefined) {
                        this.productRequest.idListCategoryColorSelection = '';

                        this.productRequest.categoryColorSelectionSelected.forEach(item => {
                            this.productRequest.idListCategoryColorSelection += `${item.id};`
                        });
                    }

                    const result = await this.$store.dispatch("productModule/CreateUpdate", this.productRequest);

                    this.productRequest.id = result.idProduct;

                    if (await this.savePhotos() && await this.saveDocs()) {

                        if (result.success === true) {
                            this.showToast("success", "Success!", result.message);
                            this.cancel();
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
            }
        },

        created() {
            this.showLoading();

            this.getRegister();
            this.getLists();

            this.hideLoading();
        }
    })
</script>


<style scoped>

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

    .adjustmentInput > .v-input--selection-controls {
        padding-top: 0px !important;
        margin-top: 0px !important;
    }

</style>

<style>

    .v-text-field__suffix {
        font-size: 12px !important;
        font-weight: bold;
    }
    
    .v-file-input > .v-input__control {
        height: 55px !important;
    }
</style>